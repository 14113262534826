@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/png/image2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-bg {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/png/image2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: "transparent";
  /* #212032; */
}

::-webkit-scrollbar-thumb {
  background: #1178f880;
}

::-webkit-scrollbar-thumb:hover {
  background: #1178f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div .button {
  margin: 0 15px 15px 0;
}

.video-contain video {
  object-fit: contain;
  overflow: hidden
}

textarea:focus {
  outline: none;
  border-color: none;
  box-shadow: none;
}

input:focus-visible {
  outline: none;
  border-color: none;
  box-shadow: none;
}

.video-cover video {
  position: absolute;
  object-fit: cover;

}

.fetching-details {
  background-image: url("./assets/png/image2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@font-face {
  font-family: 'Poppins';
  src: url("./fonts/Poppins-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins Bold';
  src: url("./fonts/Poppins-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins ExtraBold';
  src: url("./fonts/Poppins-ExtraBold.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins Italic';
  src: url("./fonts/Poppins-Italic.ttf") format('truetype');
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.spinner-border {
  vertical-align: -0.125em;
  border: 0.25em solid;
  border-right-color: transparent;
}

@media screen and (max-width: 768px) {
  .video-cover video {
    object-fit: contain;
  }
}

html,
body,
#root {
  height: 100%;
}

#root {
  overscroll-behavior-y: none;
}

/* scroll bar */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #657080;
  border-radius: 5px;
}

code,
kbd,
samp,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: none;
}

/**
 * needed for safari. Safari
 * adds background color on its own
 * on hls-viewer on fullscreen
 */
#hls-viewer-dark:fullscreen {
  /* background-color: black !important; */
}

#hls-viewer-light:fullscreen {
  background-color: white !important;
}