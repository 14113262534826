.latest-container {
  /* background: url("../../../assets/png/Frame\ 3.png"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-position-y: 100px;
}

.latest-container-inner {
  background: rgba(29, 29, 29, 0.1);
  backdrop-filter: blur(40px) !important;
  box-shadow: 0 8px 32px 0 rgba(29, 29, 29, 0.37);

  -webkit-backdrop-filter: blur(40px);
}

.bordered-text div {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #676767;
  -webkit-text-stroke-width: calc(1em / 128);
  font-family: "DmSans Bold";
  font-weight: bold;
  font-size: 100px;
  font-style: italic;
}

.latest-container-hd-container {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
}

.latest-container-head {
  letter-spacing: 1.2px;
  font-weight: 900;
  line-height: 1.2;
  padding: 20px;
  background: rgba(212, 61, 228, 0.1);
  box-shadow: 0 8px 32px 0 rgba(29, 29, 29, 0.37);
  backdrop-filter: blur(14.5px);
  -webkit-backdrop-filter: blur(14.5px);
}

.instagram-post-container {
  width: 100%;
  aspect-ratio: 1 / 1;
  box-shadow: 0px 4px 69px rgba(255, 255, 255, 0.1);
}

.latest-instagram-icon {
  top: 50%;
  left: 50%;
  font-size: 40px;
  transform: translate(-50%, -50%);
}

.latest-instagram-icon svg {
  filter: drop-shadow(0px 4px 24px rgba(255, 255, 255, 0.9));
}

.youtube-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 69px rgba(76, 76, 102, 0.56);
  border-radius: 4px;
  overflow: hidden;
}

.youtube-container iframe {
  position: absolute;
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
}

/* @media screen and (max-width: 1270px) {
  .bordered-text div {
    font-size: 80px;
  }
}

@media screen and (max-width: 1024px) {
  .bordered-text div {
    font-size: 60px;
  }
}

@media screen and (max-width: 800px) {
  .bordered-text div {
    font-size: 40px;
  }
} */

@media screen and (max-width: 767px) {
  .new-latest-content-container {
    padding: 0px 10% !important;
  }

  .latest-container {
    background: none;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 10px !important;
  }

  .latest-container,
  .latest-container-inner {
    padding: 50px 0px;
  }

  .latest-container-head {
    padding: 0px;
    background: none;
    box-shadow: none;
    backdrop-filter: none;
    font-size: 48px;
  }

  .latest-container-hd-container {
    position: relative;
    margin-top: -50px;
    align-items: flex-start;
    padding: 0px 10%;
    color: #DD00A9;
  }

  .latest-card-container {
    padding: 0% 10%;
    color: #DD00A9;
  }
}

@media screen and (max-width: 576px) {}