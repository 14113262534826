.otp-group {
  display: flex;
  width: 100%;
  /* max-width: 360px; */
  column-gap: 20px;
}

.otp-input {
  width: 45px !important;
  height: 35px !important;
  border-radius: 10px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}

@media (min-width: 544px) {
  .otp-input {
    width: 45px !important;
    height: 35px !important;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .otp-input {
    width: 60px !important;
    height: 47px !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .otp-input {
    width: 65px !important;
    height: 50px !important;
  }
}